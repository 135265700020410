var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "900px", height: "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v(" close ")]
      ),
      _c("div", { staticClass: "popup_cont", attrs: { id: "frmReq" } }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.032"))),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("p", { staticClass: "txt_desc none" }, [
            _vm._v(" " + _vm._s(_vm.$t("msg.VOSD100_M5.033")) + " "),
          ]),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.002")))]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "polPol",
                        "ctr-cd": _vm.pol.ctrCd,
                        "plc-cd": _vm.pol.plcCd,
                        disabled: true,
                      },
                      on: { change: _vm.changePol },
                    }),
                  ],
                  1
                ),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100.003")))]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-place", {
                      attrs: {
                        id: "podPod",
                        "ctr-cd": _vm.pod.ctrCd,
                        "plc-cd": _vm.pod.plcCd,
                        disabled: true,
                      },
                      on: { change: _vm.changePod },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.MAIN100.017")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c(
                    "div",
                    { staticClass: "input_calendar" },
                    [
                      _c("e-date-picker", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.params.yyyymmdd,
                          callback: function ($$v) {
                            _vm.$set(_vm.params, "yyyymmdd", $$v)
                          },
                          expression: "params.yyyymmdd",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
          _c("h2", { staticClass: "content_title" }, [
            _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.070"))),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.negoYN !== "Y",
                    expression: "negoYN !== 'Y'",
                  },
                ],
              },
              [_vm._v("(" + _vm._s(_vm.$t("msg.VOSD100_M5.071")) + ")")]
            ),
          ]),
          _c(
            "table",
            { staticClass: "tbl_row mt10" },
            [
              _vm._m(1),
              _c("tbody", [
                _c("tr", [
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.actFlag === "01",
                          expression: "params.actFlag === '01'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.111")))]
                  ),
                  _c(
                    "th",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.params.actFlag === "02",
                          expression: "params.actFlag === '02'",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.112")))]
                  ),
                  _c(
                    "td",
                    { attrs: { colspan: "3" } },
                    [
                      _c("e-auto-complete-cst-enm", {
                        staticClass: "col_4",
                        attrs: {
                          id: "actCst",
                          "ctr-cd": _vm.params.actCtrCd,
                          "cd-nm": _vm.params.actShprCstEnm,
                          "cst-cat-cd": _vm.params.cstCatCd,
                        },
                        on: { change: _vm.actChange },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._l(_vm.params.containerList, function (vo, idx) {
                return _c("tbody", { key: "tr_container_" + idx }, [
                  _c("tr", { staticClass: "divbar" }, [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.113")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.size,
                              expression: "vo.size",
                            },
                          ],
                          attrs: { name: "size", id: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "size",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeSize(vo, idx)
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                          ]),
                          _vm._l(_vm.sizeList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.cd,
                                domProps: { value: option.cd },
                              },
                              [_vm._v(" " + _vm._s(option.cdNm) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.114")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.type,
                              expression: "vo.type",
                            },
                          ],
                          attrs: { name: "type", id: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.changeType(vo, idx, "type")
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                          ]),
                          _vm._l(_vm.cntrTypeList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.cd,
                                domProps: { value: option.cd },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(option.cdNm) +
                                    " / " +
                                    _vm._s(option.cd) +
                                    " "
                                ),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.034")))]),
                    _c("td", { attrs: { colspan: "3" } }, [
                      _c("span", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.dangerCargo,
                              expression: "vo.dangerCargo",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "dCargo1_" + idx,
                            name: "dCargo",
                            "true-value": "Y",
                            "false-value": "N",
                          },
                          domProps: {
                            checked: Array.isArray(vo.dangerCargo)
                              ? _vm._i(vo.dangerCargo, null) > -1
                              : _vm._q(vo.dangerCargo, "Y"),
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = vo.dangerCargo,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        vo,
                                        "dangerCargo",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        vo,
                                        "dangerCargo",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(vo, "dangerCargo", $$c)
                                }
                              },
                              function ($event) {
                                return _vm.clickDCargo(vo)
                              },
                            ],
                          },
                        }),
                        _c("label", { attrs: { for: "dCargo1_" + idx } }, [
                          _c("span", [_vm._v("Dangerous Cargo")]),
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.oog,
                              expression: "vo.oog",
                            },
                          ],
                          attrs: {
                            type: "checkbox",
                            id: "oog1_" + idx,
                            name: "oog",
                            "true-value": "Y",
                            "false-value": "N",
                            disabled: vo.disabled === "Y",
                          },
                          domProps: {
                            checked: Array.isArray(vo.oog)
                              ? _vm._i(vo.oog, null) > -1
                              : _vm._q(vo.oog, "Y"),
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = vo.oog,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? "Y" : "N"
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(vo, "oog", $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        vo,
                                        "oog",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(vo, "oog", $$c)
                                }
                              },
                              function ($event) {
                                return _vm.clickOOG(vo)
                              },
                            ],
                          },
                        }),
                        _c(
                          "label",
                          {
                            attrs: {
                              for: "oog1_" + idx,
                              disabled: vo.disabled === "Y",
                            },
                          },
                          [_c("span", [_vm._v("OOG")])]
                        ),
                      ]),
                    ]),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.115")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.currency,
                              expression: "vo.currency",
                            },
                          ],
                          attrs: { name: "currency", id: "" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "currency",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationCntnList(
                                  "currency",
                                  idx
                                )
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                          ]),
                          _c("option", { attrs: { value: "USD" } }, [
                            _vm._v("USD"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.116")))]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "reqRate",
                            name: "reqRate",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.removeMsgNumber("reqRate", idx)
                            },
                          },
                          model: {
                            value: vo.reqRate,
                            callback: function ($$v) {
                              _vm.$set(vo, "reqRate", $$v)
                            },
                            expression: "vo.reqRate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.117")))]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "proQty",
                            name: "proQty",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.removeMsgNumber("proQty", idx)
                            },
                          },
                          model: {
                            value: vo.proQty,
                            callback: function ($$v) {
                              _vm.$set(vo, "proQty", $$v)
                            },
                            expression: "vo.proQty",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.118")))]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "weight",
                            name: "weight",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.removeMsgNumber("weight", idx)
                            },
                          },
                          model: {
                            value: vo.weight,
                            callback: function ($$v) {
                              _vm.$set(vo, "weight", $$v)
                            },
                            expression: "vo.weight",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("tr", [
                    _c("th", [
                      _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.036")) + "(USD)"),
                    ]),
                    _c(
                      "td",
                      [
                        _c("e-input-number", {
                          attrs: {
                            "is-comma": true,
                            id: "othersLvl",
                            name: "othersLvl",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.removeMsgNumber("othersLvl", idx)
                            },
                          },
                          model: {
                            value: vo.cmptCoFrt,
                            callback: function ($$v) {
                              _vm.$set(vo, "cmptCoFrt", $$v)
                            },
                            expression: "vo.cmptCoFrt",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.037")))]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.cmptCoCstNo,
                              expression: "vo.cmptCoCstNo",
                            },
                          ],
                          attrs: { name: "others", id: "others" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  vo,
                                  "cmptCoCstNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                              function ($event) {
                                return _vm.checkValidationCntnList(
                                  "others",
                                  idx
                                )
                              },
                            ],
                          },
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                          ]),
                          _vm._l(_vm.otherCompanyList, function (option) {
                            return _c(
                              "option",
                              {
                                key: option.cstNo,
                                domProps: { value: option.cstNo },
                              },
                              [_vm._v(" " + _vm._s(option.cstEnm) + " ")]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                ])
              }),
            ],
            2
          ),
          _c("p", { staticClass: "mt5 text_right" }, [
            _c("span", [
              _c(
                "button",
                {
                  staticClass: "tbl_icon plus",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.addRow.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("plus")]
              ),
            ]),
            _c("span", { staticClass: "ml2" }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.miuns === "Y",
                      expression: "miuns === 'Y'",
                    },
                  ],
                  staticClass: "tbl_icon minus",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.deleteRow.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("minus")]
              ),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.miuns === "N",
                      expression: "miuns === 'N'",
                    },
                  ],
                  staticClass: "tbl_icon minus disabled",
                },
                [_vm._v("minus")]
              ),
            ]),
          ]),
          _c("table", { staticClass: "tbl_row mt10" }, [
            _vm._m(2),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.038")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("span", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.frgtCat,
                          expression: "params.frgtCat",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "cargo1",
                        name: "cargo",
                        value: "01",
                      },
                      domProps: { checked: _vm._q(_vm.params.frgtCat, "01") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationCargo()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "frgtCat", "01")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "cargo1" } },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.039")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.frgtCat,
                          expression: "params.frgtCat",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "cargo2",
                        name: "cargo",
                        value: "02",
                      },
                      domProps: { checked: _vm._q(_vm.params.frgtCat, "02") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationCargo()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "frgtCat", "02")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "cargo2" } },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.040")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.frgtCat,
                          expression: "params.frgtCat",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "cargo3",
                        name: "cargo",
                        value: "03",
                      },
                      domProps: { checked: _vm._q(_vm.params.frgtCat, "03") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationCargo()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "frgtCat", "03")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "cargo3" } },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.041")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.frgtCat,
                          expression: "params.frgtCat",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "cargo4",
                        name: "cargo",
                        value: "04",
                      },
                      domProps: { checked: _vm._q(_vm.params.frgtCat, "04") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationCargo()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "frgtCat", "04")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "cargo4" } },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.042")))]
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.043")))]),
                _c("td", [
                  _c("span", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.ldgTcnt,
                          expression: "params.ldgTcnt",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "shipment1",
                        name: "shipment",
                        value: "R",
                      },
                      domProps: { checked: _vm._q(_vm.params.ldgTcnt, "R") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationShip()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "ldgTcnt", "R")
                        },
                      },
                    }),
                    _c(
                      "label",
                      { staticClass: "mr10", attrs: { for: "shipment1" } },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.119")))]
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.ldgTcnt,
                          expression: "params.ldgTcnt",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "shipment2",
                        name: "shipment",
                        value: "S",
                      },
                      domProps: { checked: _vm._q(_vm.params.ldgTcnt, "S") },
                      on: {
                        click: function ($event) {
                          return _vm.fnValidationShip()
                        },
                        change: function ($event) {
                          return _vm.$set(_vm.params, "ldgTcnt", "S")
                        },
                      },
                    }),
                    _c(
                      "label",
                      {
                        staticClass: "mr10",
                        staticStyle: { "margin-right": "0px !important" },
                        attrs: { for: "shipment2" },
                      },
                      [_c("span"), _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.120")))]
                    ),
                  ]),
                ]),
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.121")))]),
                _c(
                  "td",
                  [
                    _c("e-auto-complete-commodity", {
                      staticClass: "col_8",
                      attrs: {
                        id: "commodity",
                        "ctr-cd": _vm.ctrCd,
                        "cd-nm": _vm.params.commodityNm,
                      },
                      on: { change: _vm.changeCommodity },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.044"))),
                  _c("br"),
                  _vm._v("(TEU/" + _vm._s(_vm.$t("msg.VOSD100_M5.035")) + ")"),
                ]),
                _c(
                  "td",
                  [
                    _c("e-input-number", {
                      attrs: { "is-comma": true, id: "allQtyLvl" },
                      on: {
                        input: function ($event) {
                          return _vm.removeMsgNo("allQtyLvl")
                        },
                      },
                      model: {
                        value: _vm.params.allTeu,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "allTeu", $$v)
                        },
                        expression: "params.allTeu",
                      },
                    }),
                  ],
                  1
                ),
                _c("th", [
                  _vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.045"))),
                  _c("br"),
                  _vm._v("(TEU/" + _vm._s(_vm.$t("msg.VOSD100_M5.035")) + ")"),
                ]),
                _c(
                  "td",
                  [
                    _c("e-input-number", {
                      attrs: { "is-comma": true, id: "tgtQtyLvl" },
                      on: {
                        input: function ($event) {
                          return _vm.removeMsgNo("tgtQtyLvl")
                        },
                      },
                      model: {
                        value: _vm.params.targTeu,
                        callback: function ($$v) {
                          _vm.$set(_vm.params, "targTeu", $$v)
                        },
                        expression: "params.targTeu",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.046")))]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("div", { staticClass: "tbl_form" }, [
                    _c(
                      "span",
                      { staticClass: "d-inline-block" },
                      [
                        _c("e-date-range-picker-simple", {
                          staticClass: "wid240",
                          attrs: { id: "lodingPeriod" },
                          on: { change: _vm.changeDateRange },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "span",
                      { staticClass: "d-inline-block" },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.params.volume,
                                expression: "params.volume",
                              },
                            ],
                            staticClass: "wid120",
                            attrs: { name: "", id: "volume" },
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.params,
                                    "volume",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.fnValidationVolume()
                                },
                              ],
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v("select"),
                            ]),
                            _vm._l(_vm.volumeList, function (vo) {
                              return _c(
                                "option",
                                {
                                  key: vo.cmdtCd,
                                  domProps: { value: vo.cmdtCd },
                                },
                                [_vm._v(_vm._s(vo.cmdtDsc))]
                              )
                            }),
                          ],
                          2
                        ),
                        _c("e-input-number", {
                          staticClass: "wid80",
                          attrs: { "is-comma": true, id: "times" },
                          on: {
                            input: function ($event) {
                              return _vm.removeMsgNo("times")
                            },
                          },
                          model: {
                            value: _vm.params.ldgCyc,
                            callback: function ($$v) {
                              _vm.$set(_vm.params, "ldgCyc", $$v)
                            },
                            expression: "params.ldgCyc",
                          },
                        }),
                        _vm._v(
                          " " + _vm._s(_vm.$t("msg.VOSD100_M5.047")) + " "
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.VOSD100_M5.124")))]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.params.selPic,
                          expression: "params.selPic",
                        },
                      ],
                      attrs: { name: "", id: "selPic" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.params,
                              "selPic",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function ($event) {
                            return _vm.fnValidationSelPic()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [
                        _vm._v(_vm._s(_vm.$t("msg.CSBL200.075"))),
                      ]),
                      _vm._l(_vm.picNmList, function (vo) {
                        return _c(
                          "option",
                          { key: vo.picNo, domProps: { value: vo.picNo } },
                          [_vm._v(_vm._s(vo.picNm))]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _c("td", { attrs: { colspan: "3" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.bizPicTelNo,
                        expression: "params.bizPicTelNo",
                      },
                    ],
                    staticClass: "wid120 mr10",
                    attrs: { type: "text" },
                    domProps: { value: _vm.params.bizPicTelNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.params, "bizPicTelNo", $event.target.value)
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.params.bizPicEmlAddr,
                        expression: "params.bizPicEmlAddr",
                      },
                    ],
                    staticClass: "wid200 ml10",
                    attrs: { type: "text" },
                    domProps: { value: _vm.params.bizPicEmlAddr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.params,
                          "bizPicEmlAddr",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: { click: _vm.sendRequestQuote },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.009")))]
          ),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.SPEC010G010.010")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "55%" } }),
      _c("col", { attrs: { width: "130" } }),
      _c("col", { attrs: { width: "40%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }