<template>
  <div
    class="popup_wrap"
    style="width:900px;height:600px;"
  >
    <button
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont" id="frmReq">
      <h1 class="page_title">{{ $t('msg.VOSD100_M5.032') }}</h1><!-- 운임 문의 -->
      <div class="content_box">
        <!-- content_box -->
        <p class="txt_desc none">
          {{ $t('msg.VOSD100_M5.033') }}
          <!-- 견적 관련 상세 내용을 입력하시면 담당 영업사원이 빠른 시간내에 회신 드리도록 하겠습니다. -->
        </p>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="140"><col><col width="130"><col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.VOSD100.002') }}</th><!-- 출발 -->
              <td>
                <e-auto-complete-place
                  id="polPol"
                  @change="changePol"
                  :ctr-cd="pol.ctrCd"
                  :plc-cd="pol.plcCd"
                  :disabled="true"
                />
              </td>
              <th>{{ $t('msg.VOSD100.003') }}</th><!-- 도착 -->
              <td>
                <e-auto-complete-place
                  id="podPod"
                  @change="changePod"
                  :ctr-cd="pod.ctrCd"
                  :plc-cd="pod.plcCd"
                  :disabled="true"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.MAIN100.017') }}</th><!-- 예상 출항일 -->
              <td colspan="3">
                <div class="input_calendar">
                  <e-date-picker
                    v-model="params.yyyymmdd"
                    :disabled="true"
                  />
                </div>
              </td>
              <!-- <th>Freight Term</th>
              <td>
                <span>
                  <input type="radio" id="freight1" name="freight" v-model="params.freight" value="P">
                  <label for="freight1" class="mr10"><span></span>Prepaid</label>
                  <input type="radio" id="freight2" name="freight" v-model="params.freight" value="C">
                  <label for="freight2" class="mr10"><span></span>Collect</label>
                </span>
              </td> -->
            </tr>
          </tbody>
        </table>
        <h2 class="content_title">{{ $t('msg.VOSD100_M5.070') }}<span v-show="negoYN !== 'Y'">({{ $t('msg.VOSD100_M5.071') }})</span></h2><!-- 부가정보 (선택)-->
        <table class="tbl_row mt10">
          <colgroup>
            <col width="140"><col><col width="130"><col><!-- <col width="50"> -->
          </colgroup>
          <tbody>
            <tr>
              <th v-show="params.actFlag === '01'">{{ $t('msg.VOSD100_M5.111') }}<!-- Actual Shipper --></th>
              <th v-show="params.actFlag === '02'">{{ $t('msg.VOSD100_M5.112') }}<!-- Actual Consignee --></th>
              <td colspan="3">
                <e-auto-complete-cst-enm
                  class="col_4"
                  id="actCst"
                  @change="actChange"
                  :ctr-cd="params.actCtrCd"
                  :cd-nm="params.actShprCstEnm"
                  :cst-cat-cd="params.cstCatCd"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-for="(vo, idx) in params.containerList" :key="'tr_container_'+ idx">
            <tr class="divbar">
              <th>{{ $t('msg.VOSD100_M5.113') }}<!-- Size --></th>
              <td>
                <select name="size" id="" v-model="vo.size" @change="changeSize(vo, idx)">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option
                    v-for="option in sizeList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }}
                  </option>
                </select>
              </td>
              <th>{{ $t('msg.VOSD100_M5.114') }}<!-- Type --></th>
              <td>
                <select name="type" id="" v-model="vo.type" @change="changeType(vo, idx, 'type')">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option
                    v-for="option in cntrTypeList"
                    :key="option.cd"
                    :value="option.cd"
                  >
                    {{ option.cdNm }} / {{ option.cd }}
                  </option>
                </select>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.034') }}</th><!-- 특이사항 -->
              <td colspan="3">
                <span>
                  <input
                    type="checkbox"
                    :id="'dCargo1_' + idx"
                    name="dCargo"
                    v-model="vo.dangerCargo"
                    true-value="Y"
                    false-value="N"
                    @change="clickDCargo(vo)"
                  >
                  <label :for="'dCargo1_' + idx"><span>Dangerous Cargo</span></label>
                  <!-- <input type="radio" :id="'dCargo1_' + idx" :name="'dCargo' + idx" v-model="vo.dangerCargo" value="D">
                  <label :for="'dCargo1_' + idx" class="mr10"><span></span>Dangerous Cargo</label>
                  <input type="radio" :id="'dCargo2_' + idx" :name="'dCargo' + idx" v-model="vo.dangerCargo" value="O" :disabled="vo.disabled === 'Y'">
                  <label :for="'dCargo2_' + idx" class="mr10"><span></span>OOG</label> -->
                  <input
                    type="checkbox"
                    :id="'oog1_' + idx"
                    name="oog"
                    v-model="vo.oog"
                    true-value="Y"
                    false-value="N"
                    :disabled="vo.disabled === 'Y'"
                    @change="clickOOG(vo)"
                  >
                  <label :for="'oog1_' + idx" :disabled="vo.disabled === 'Y'"><span>OOG</span></label>
                </span>
                <!-- Type이 OT, FR인 경우에만 체크박스 활성화  -->
                <!-- <div class="mt5" style="display: block;">
                  <span class="d_inline_block valign_middle pb5">
                    <input
                      type="checkbox"
                      :id="'oog1_' + idx"
                      name="oog"
                      v-model="vo.oog"
                      true-value="Y"
                      false-value="N"
                      :disabled="vo.disabled === 'Y'"
                    >
                    <label :for="'oog1_' + idx" :disabled="vo.disabled === 'Y'"><span>OOG</span></label>
                  </span>
                  <span class="d_inline_block valign_middle ml10">
                    <span>:</span>
                    <span class="ml10">Width(cm)</span>
                    <e-input-number class="wid80" :isComma="true" id="width" v-model="vo.width" :readonly="vo.oog==='N'">
                    </e-input-number>
                    <span class="ml10">Length(cm)</span>
                    <e-input-number class="wid80" :isComma="true" id="length" v-model="vo.length" :readonly="vo.oog==='N'">
                    </e-input-number>
                    <span class="ml10">Height(cm)</span>
                    <e-input-number class="wid80" :isComma="true" id="height" v-model="vo.height" :readonly="vo.oog==='N'">
                    </e-input-number>
                  </span>
                </div> -->
                <!-- Type이 OT, FR인 경우에만 체크박스 활성화 // -->
              </td>
              <!-- <td rowspan="4"><span><button class="tbl_icon minus disabled">minus</button></span></td> -->
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.115') }}<!-- Currency --></th>
              <td>
                <select name="currency" id="" v-model="vo.currency" @change="checkValidationCntnList('currency', idx)">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option value="USD">USD</option>
                </select>
              </td>
              <th>{{ $t('msg.VOSD100_M5.116') }}<!-- Ideal Rate(USD) --></th>
              <td>
                <e-input-number :is-comma="true" id="reqRate" name="reqRate" v-model="vo.reqRate" @input="removeMsgNumber('reqRate', idx)">
                </e-input-number>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.117') }}<!-- Volume(TEU/월) --></th>
              <td>
                <e-input-number :is-comma="true" id="proQty" name="proQty" v-model="vo.proQty" @input="removeMsgNumber('proQty', idx)">
                </e-input-number>
              </td>
              <th>{{ $t('msg.VOSD100_M5.118') }}<!-- Weight(KG/Van) --></th>
              <td>
                <e-input-number :is-comma="true" id="weight" name="weight" v-model="vo.weight" @input="removeMsgNumber('weight', idx)">
                </e-input-number>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.036') }}(USD)</th>
              <td>
                <e-input-number :is-comma="true" id="othersLvl" name="othersLvl" v-model="vo.cmptCoFrt" @input="removeMsgNumber('othersLvl', idx)">
                </e-input-number>
              </td>
              <th>{{ $t('msg.VOSD100_M5.037') }}</th>
              <td>
                <select name="others" id="others" v-model="vo.cmptCoCstNo" @change="checkValidationCntnList('others', idx)">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option
                    v-for="option in otherCompanyList"
                    :key="option.cstNo"
                    :value="option.cstNo"
                  >
                    {{ option.cstEnm }}
                  </option>
                </select>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="mt5 text_right">
          <span>
            <button class="tbl_icon plus" @click.prevent="addRow">plus</button></span><span class="ml2">
            <button v-show="miuns === 'Y'" class="tbl_icon minus" @click.prevent="deleteRow">minus</button>
            <button v-show="miuns === 'N'" class="tbl_icon minus disabled">minus</button>
          </span>
        </p>
        <!-- <td rowspan="5">
          <span v-show="idx === 0"><button class="tbl_icon plus" @click="addRow">plus</button></span>
          <span v-show="idx !== 0"><button class="tbl_icon minus" @click="deleteRow(idx)">minus</button></span>
        </td> -->
        <table class="tbl_row mt10">
          <colgroup>
            <col width="140"><col width="55%"><col width="130"><col width="40%">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.038') }}</th><!-- 화물구분 -->
              <td colspan="3">
                <span>
                  <input type="radio" id="cargo1" name="cargo" v-model="params.frgtCat" value="01" @click="fnValidationCargo()">
                  <label for="cargo1" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.039') }}</label><!-- 기존 당사 선적화물 -->
                  <input type="radio" id="cargo2" name="cargo" v-model="params.frgtCat" value="02" @click="fnValidationCargo()">
                  <label for="cargo2" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.040') }}</label><!-- 타선사 선적화물 -->
                  <input type="radio" id="cargo3" name="cargo" v-model="params.frgtCat" value="03" @click="fnValidationCargo()">
                  <label for="cargo3" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.041') }}</label><!-- 신규 확보화물 -->
                  <input type="radio" id="cargo4" name="cargo" v-model="params.frgtCat" value="04" @click="fnValidationCargo()">
                  <label for="cargo4" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.042') }}</label><!-- 영업진행중인 화물 -->
                </span>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.043') }}</th><!-- 선적횟수 -->
              <td>
                <span>
                  <input type="radio" id="shipment1" name="shipment" v-model="params.ldgTcnt" value="R" @click="fnValidationShip()">
                  <label for="shipment1" class="mr10"><span></span>{{ $t('msg.VOSD100_M5.119') }}<!-- Regular shipment --></label>
                  <input type="radio" id="shipment2" name="shipment" v-model="params.ldgTcnt" value="S" @click="fnValidationShip()">
                  <label for="shipment2" class="mr10" style="margin-right: 0px !important;"><span></span>{{ $t('msg.VOSD100_M5.120') }}<!-- Spot shipment --></label>
                </span>
              </td>
              <th>{{ $t('msg.VOSD100_M5.121') }}<!-- Commodity --></th>
              <td>
                <e-auto-complete-commodity
                  id="commodity"
                  class="col_8"
                  @change="changeCommodity"
                  :ctr-cd="ctrCd"
                  :cd-nm="params.commodityNm"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.044') }}<br />(TEU/{{ $t('msg.VOSD100_M5.035') }})</th><!-- 전체 물량 수준 -->
              <td>
                <e-input-number :is-comma="true" id="allQtyLvl" v-model="params.allTeu" @input="removeMsgNo('allQtyLvl')">
                </e-input-number>
              </td>
              <th>{{ $t('msg.VOSD100_M5.045') }}<br />(TEU/{{ $t('msg.VOSD100_M5.035') }})</th><!-- 타겟 물량 수준 -->
              <td>
                <e-input-number :is-comma="true" id="tgtQtyLvl" v-model="params.targTeu" @input="removeMsgNo('tgtQtyLvl')">
                </e-input-number>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.046') }}</th><!-- 선적기간/주기 -->
              <td colspan="3">
                <div class="tbl_form">
                  <span class="d-inline-block">
                    <e-date-range-picker-simple id="lodingPeriod" class="wid240" @change="changeDateRange" />
                  </span>
                  <span class="d-inline-block">
                    <select name="" id="volume" class="wid120" v-model="params.volume" @change="fnValidationVolume()">
                      <option value="">select</option>
                      <option v-for="vo in volumeList" :key="vo.cmdtCd" :value="vo.cmdtCd">{{ vo.cmdtDsc }}</option>
                    </select>

                    <e-input-number class="wid80" :is-comma="true" id="times" v-model="params.ldgCyc" @input="removeMsgNo('times')">
                    </e-input-number> {{ $t('msg.VOSD100_M5.047') }}
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.VOSD100_M5.124') }}</th><!-- 업무 담당자 -->
              <td>
                <!-- <td colspan="3"> -->
                <select name="" id="selPic" v-model="params.selPic" @change="fnValidationSelPic()">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option v-for="vo in picNmList" :key="vo.picNo" :value="vo.picNo">{{ vo.picNm }}</option>
                </select>
              </td>
              <td colspan="3">
                <input type="text" class="wid120 mr10" v-model="params.bizPicTelNo">
                <input type="text" class="wid200 ml10" v-model="params.bizPicEmlAddr">
              </td>
            </tr>
            <!-- <tr>
              <th>{{ $t('msg.VOSD100_M5.048') }}</th>
              <td>
                <select name="" id="selSal" v-model="params.selSal" @change="fnValidationSelSal()">
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option :value="params.salUno">{{ params.bussPicNm }}</option>
                </select>
              </td>
              <td colspan="3">
                <input type="text" class="wid120 mr10" v-model="params.bussPicTelNo">
                <input type="text" class="wid200 ml10" v-model="params.bussPicEmlAddr">
              </td>
            </tr> -->
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click="sendRequestQuote">{{ $t('msg.SPEC010G010.009') }}</a><!-- 확인 -->
        <a class="button gray lg" href="#" @click="$emit('close')">{{ $t('msg.SPEC010G010.010') }}</a><!-- 닫기 -->
      </div>
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import schedule from '@/api/rest/schedule/schedule'
import EAutoCompletePlace from '@/components/common/EAutoCompletePlace'
import EAutoCompleteCommodity from '@/components/common/EAutoCompleteCommodity'
import EInputNumber from '@/components/common/EInputNumber'

export default {
  name: 'RequestQuotePop',
  components: {
    EAutoCompletePlace,
    EAutoCompleteCommodity,
    EInputNumber,
    EDatePicker: () => import('@/components/common/EDatePicker'),
    EDateRangePickerSimple: () => import('@/components/common/EDateRangePickerSimple'),
    EAutoCompleteCstEnm: () => import('@/components/common/EAutoCompleteCstEnmV2')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          porCtrCd: '',
          porPlcCd: '',
          dlyCtrCd: '',
          dlyPlcCd: '',
          polNm: '',
          podNm: '',
          etd: '',
          frtAppNo: '',
          vslCd: '',
          vslNm: '',
          voyNo: '',
          rteCd: '',
          eiCatCd: '',
          frtResult: '',
          reqRno: '',
          bkgClose: '',
          raTsParam: ''
        }
      }
    }
  },
  data: function () {
    return {
      pol: {
        ctrCd: '',
        plcCd: ''
      },
      pod: {
        ctrCd: '',
        plcCd: ''
      },
      ctrCd: '',
      negoYN: '',
      params: {
        yyyymmdd: '',
        commodityNm: '',
        commodityCd: '',
        volume: '',
        allTeu: '',
        targTeu: '',
        ldgCyc: '',
        salNm: '',
        salUno: '',
        selPic: '',
        selSal: '',
        freight: 'P',
        actual: 'shipper',
        actShprCstEnm: '',
        frgtCat: '',
        ldgTcnt: '',
        ldgTrmStrDt: '',
        ldgTrmEndDt: '',
        containerList: [],
        polCtrCd: '',
        polPortCd: '',
        podCtrCd: '',
        podPortCd: '',
        porCtrCd: '',
        porPlcCd: '',
        dlyCtrCd: '',
        dlyPlcCd: '',
        submitYn: 'Y',
        actFlag: '01', // Actual Shipper(01) or Actual Consignee(02)
        actCtrCd: '',
        wtCatCd: 'K',
        cstCatCd: '30',
        bussPicTelNo: '',
        bussPicEmlAddr: '',
        cmdtCd: '',
        prcgCmdtDsc: ''
      },
      volumeList: [],
      picNmList: [],
      otherCompanyList: [],
      sizeList: [
        { cdNm: '20', cd: '20' },
        { cdNm: '40', cd: '40' },
        { cdNm: '45', cd: '45' }
      ],
      cntrTypeList: [],
      cntrTypeAllList: [
        { cdNm: 'Dry', cd: 'GP', rmk: '20/40' },
        { cdNm: 'HIGH CUBE', cd: 'HC', rmk: '40/45' },
        { cdNm: 'REEFER', cd: 'RF', rmk: '20' },
        { cdNm: 'REEFER HIGH CUBE', cd: 'RH', rmk: '40' },
        { cdNm: 'TANK', cd: 'TK', rmk: '20/40' },
        { cdNm: 'OPEN TOP', cd: 'OT', rmk: '20/40' },
        { cdNm: 'FLAT RACK', cd: 'FR', rmk: '20/40' },
        { cdNm: 'SUPER RACK', cd: 'SR', rmk: '40' }
      ],
      otherCompany: [
        { cd: 'AA', cdNm: 'AA Company' },
        { cd: 'BB', cdNm: 'BB Company' },
        { cd: 'CC', cdNm: 'CC Company' },
        { cd: 'DD', cdNm: 'DD Company' }
      ],
      miuns: 'N',
      salNm: '',
      bussPicTelNo: '',
      bussPicEmlAddr: ''
    }
  },
  watch: {
    yyyymmdd () {
      console.log('>>>>>> [RequestQuotePop] yyyymmdd : ' + this.params.yyyymmdd)
    },
    'params.actFlag': function (val) {
      this.params.cstCatCd = val === '01' ? '30' : '31'
    },
    'pod.ctrCd': function (val) {
      console.log('pod.ctrCd = ' + val)
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    // console.log('##### this.parentInfo > ', this.parentInfo)
    if (this.$ekmtcCommon.isNotEmpty(this.parentInfo.etd)) {
      const etd = this.parentInfo.etd

      const year = etd.substring(0, 4)
      const month = etd.substring(4, 6)
      const day = etd.substring(6, 8)

      this.year = year
      this.month = Number(month)
      this.day = Number(day)
    } else {
      const date = new Date()
      this.year = date.getFullYear()
      this.month = date.getMonth() + 1
      this.day = date.getDate()
    }

    if (this.month < 10) {
      this.month = '0' + this.month
    }
    if (this.day < 10) {
      this.day = '0' + this.day
    }

    this.params.yyyymmdd = this.year + '.' + this.month + '.' + this.day
    if (this.parentInfo.yyyymmdd) {
      this.params.yyyymmdd = this.parentInfo.yyyymmdd
    }
    this.pol.ctrCd = this.parentInfo.porCtrCd
    this.pol.plcCd = this.parentInfo.porPlcCd
    this.pod.ctrCd = this.parentInfo.dlyCtrCd
    this.pod.plcCd = this.parentInfo.dlyPlcCd
    this.negoYN = this.parentInfo.negoYN
    this.params.polCtrCd = this.pol.ctrCd
    this.params.polPortCd = this.pol.plcCd
    this.params.porCtrCd = this.pol.ctrCd
    this.params.porPlcCd = this.pol.plcCd
    this.params.podCtrCd = this.pod.ctrCd
    this.params.podPortCd = this.pod.plcCd
    this.params.dlyCtrCd = this.pod.ctrCd
    this.params.dlyPlcCd = this.pod.plcCd

    this.ctrCd = this.memberDetail.userCtrCd
    this.params.actCtrCd = this.memberDetail.userCtrCd
    this.params = { ...this.params, ...this.parentInfo }
    if (this.parentInfo.negoYN === 'Y') {
      if (this.parentInfo.frtResult === 'Y') {
        this.params.frtAppNo2 = this.parentInfo.frtAppNo
      }
    }
    setTimeout(() => {
      this.requestQuote()
      this.fnSalUnoSet()
      this.addRow()
    }, 300)
  },
  methods: {
    changePol (vo) {
      this.pol = vo
      this.params.polCtrCd = this.pol.ctrCd
      this.params.polPortCd = this.pol.plcCd
      this.params.porCtrCd = this.pol.ctrCd
      this.params.porPlcCd = this.pol.plcCd
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelector('#polPol')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      // this.findSalUno()
      this.fnSalUnoSet()
    },
    changePod (vo) {
      this.pod = vo
      this.params.podCtrCd = this.pod.ctrCd
      this.params.podPortCd = this.pod.plcCd
      this.params.dlyCtrCd = this.pod.ctrCd
      this.params.dlyPlcCd = this.pod.plcCd
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelector('#podPod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
      // this.findSalUno()
      this.fnSalUnoSet()
    },
    changeDateRange (s, d) {
      this.params.ldgTrmStrDt = s
      this.params.ldgTrmEndDt = d
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelectorAll('#lodingPeriod')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    changeCommodity (vo) {
      this.params.cmdtCd = vo?.cd ?? ''
      this.params.prcgCmdtDsc = vo.cd ? '' : vo?.cdNm ?? ''
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelector('#commodity')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // 'Actual Shipper or Actual Consignee' 자동완성 change event
    actChange (vo) {
      this.pod = vo
      this.params.actShprCstEnm = vo.cdNm
      this.params.bkgShprCstNo = vo.cd
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelector('#actCst')
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    requestQuote () {
      vslScheduleInfo.requestQuote().then((rtnData) => {
        // console.log('######### [RequestQuotePop] requestQuote #########')
        // console.log(rtnData)
        this.volumeList = rtnData.data.volume
        this.picNmList = rtnData.data.picNm
        this.otherCompanyList = rtnData.data.otherCompanyList
      })
    },
    // 영업사원 설정
    fnSalUnoSet () {
      const porCd = this.params.porCtrCd
      const dlyCd = this.params.dlyCtrCd
      if (this.$ekmtcCommon.isEmpty(porCd) || this.$ekmtcCommon.isEmpty(dlyCd)) {
        return
      }
      let apiFlag = true
      if (apiFlag) {
        const tmpParam = {
          porCd: porCd,
          dlyCd: dlyCd
        }
        schedule.findSalUno(tmpParam)
          .then((response) => {
            // console.log('######### [RequestQuotePop] findSalUno #########')
            // console.log(response)
            if (response.status === 200) {
              const respData = response.data

              if (respData) {
                if (respData.eiCatCd !== undefined) {
                  this.params.eiCatCd = respData.eiCatCd
                  const eiCatCd = this.params.eiCatCd
                  if (eiCatCd === 'O' || eiCatCd === 'OJ' || eiCatCd === 'OJRF') {
                    this.params.eiCatCd = 'O'
                    this.params.actFlag = '01'
                    // this.params.frtTerm = 'P'
                  } else if (eiCatCd === 'I') {
                    this.params.actFlag = '02'
                    // this.params.frtTerm = 'C'
                  }
                }

                // if (respData.salNm) {
                //   this.params.bussPicNm = respData.salNm
                // }
                this.params.bussPicNm = respData?.salNm ?? ''

                // if (respData.salTelNo) {
                //   this.params.salTelNo = respData.salTelNo
                // }

                // if (respData.salUno) {
                //   this.params.salUno = respData.salUno
                // }
                this.params.salUno = respData?.salUno ?? ''

                // if (respData.salCoTelNo) {
                //   this.params.bussPicTelNo = respData.salCoTelNo
                // }
                this.params.bussPicTelNo = respData?.salCoTelNo

                // if (respData.salEmlAddr) {
                //   this.params.bussPicEmlAddr = respData.salEmlAddr
                // }
                this.params.bussPicEmlAddr = respData?.salEmlAddr ?? ''
              }
            }
          })
      }
    },
    addRow () {
      this.params.containerList.push(
        {
          size: '',
          type: '',
          dangerCargo: '',
          oog: 'N',
          width: '',
          length: '',
          height: '',
          currency: '',
          reqRate: '',
          proQty: '',
          weight: '',
          cmptCoFrt: '',
          cmptCoCstNo: '',
          sizeList: this.sizeList,
          cntrTypeList: this.cntrTypeList,
          otherCompany: this.otherCompanyList,
          disabled: 'Y'
        }
      )
      let idx = this.params.containerList.length - 1
      if (idx === 0) {
        this.miuns = 'N'
      } else {
        this.miuns = 'Y'
      }
    },
    deleteRow () {
      let idx = this.params.containerList.length - 1
      if (idx !== 0) {
        this.params.containerList.splice(idx, 1)
      }
      if (idx === 1) {
        this.miuns = 'N'
      } else {
        this.miuns = 'Y'
      }
    },
    changeSize (vo, idx, name) { // Size 변경시 호출 함수
      // Size에 해당하는 컨테이너 Filter
      if (this.$ekmtcCommon.isEmpty(vo.size)) {
        this.cntrTypeList = []
      } else if (vo.size.startsWith('45')) { // 45HC
        this.cntrTypeList = this.cntrTypeAllList.filter(cntrType => cntrType.rmk.indexOf('45') >= 0)
      } else {
        this.cntrTypeList = this.cntrTypeAllList.filter(cntrType => cntrType.rmk.indexOf(vo.size) >= 0)
      }

      // validation Check
      this.checkValidationCntnList('size', idx)
    },
    changeType (vo, idx, name) {
      if (vo.type === 'OT' || vo.type === 'FR') {
        vo.disabled = 'N'
      } else {
        vo.disabled = 'Y'
        vo.oog = 'N'
        vo.width = ''
        vo.length = ''
        vo.height = ''
      }
      this.checkValidationCntnList(name, idx)
    },
    clickDCargo (vo) {
      if (vo.dangerCargo === 'Y') {
        vo.oog = 'N'
      }
    },
    clickOOG (vo) {
      if (vo.oog === 'Y') {
        vo.dangerCargo = 'N'
      }
    },
    sendRequestQuote () {
      if (this.fnValidationAll()) {
        // - 운임 네고 : SA201M.FRT_ESTN_YN = 'N' 로 생성
        // - 운임 문의 : SA201M.FRT_ESTN_YN = 'Y' 로 생성
        this.params.negoYN = this.negoYN === 'Y' ? 'G' : 'Y'
        vslScheduleInfo.insertRequestQuote(this.params).then((rtnData) => {
          if (rtnData.status === 200) {
            if (rtnData.data.insertCnt === -1) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX070G110.022')) // 요청 접수에 실패하였습니다.
            } else {
              this.$ekmtcCommon.alertCallback(this.$t('msg.ONIM010T010.015'), () => { // 전송완료되었습니다.
                this.$emit('close')
              })
            }
          }
        })
      }
    },
    // '확인'버튼 클릭시 검색조건 전체 validation check
    fnValidationAll () {
      const frm = document.querySelector('#frmReq')
      let isAllOk = true
      // 출발지 validation check
      if (!this.fnValidationPor(frm)) {
        isAllOk = false
      }
      // 도착지 validation check
      if (!this.fnValidationDly(frm)) {
        isAllOk = false
      }
      // 예상 출항일 validation check
      // if (!this.fnValidationEtd(frm)) {
      //   isAllOk = false
      // }
      // 네고요청 validation check
      if (this.negoYN === 'Y' && !this.fnValidationNego(frm)) {
        isAllOk = false
      }
      return isAllOk
    },
    // 출발지 validation check
    fnValidationPor (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#polPol')
      let isOk = true
      let msg = ''
      if (this.$ekmtcCommon.isEmpty(this.params.porCtrCd) || this.$ekmtcCommon.isEmpty(this.params.porPlcCd)) {
        msg = this.$t('msg.FARE010T010.013') // 출발지를 입력해 주세요.
        isOk = false
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 도착지 validation check
    fnValidationDly (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#podPod')
      let isOk = true
      let msg = ''
      // if (this.$ekmtcCommon.isEmpty(this.pod.ctrCd) || this.$ekmtcCommon.isEmpty(this.pod.plcCd)) {
      //   console.log('this.pod.plcCd = ' + this.pod.plcCd)
      //   msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
      //   isOk = false
      // }
      if (this.$ekmtcCommon.isEmpty(this.params.dlyCtrCd) || this.$ekmtcCommon.isEmpty(this.params.dlyPlcCd)) {
        msg = this.$t('msg.FARE010T010.014') // 도착지를 입력해 주세요.
        isOk = false
      }
      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(elem, msg)
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 예상 출항일 validation check (오늘 날짜 + 60일을 초과할 수 없음.)
    // fnValidationEtd (frm) {
    //   if (frm === undefined) {
    //     frm = document.querySelector('#frmReq')
    //   }
    //   const elem = frm.querySelector('.datepicker')
    //   let isOk = true
    //   let msg = ''
    //   if (this.$ekmtcCommon.isEmpty(this.params.yyyymmdd)) {
    //     msg = this.$t('msg.FARE010T010.015') // 예상 출항일을 선택해 주세요.
    //     isOk = false
    //   } else {
    //     const etd = this.$ekmtcCommon.getStrToDate(this.params.yyyymmdd.replaceAll('.', ''))
    //     const today = new Date()

    //     if (etd < today) {
    //       msg = this.$t('msg.FARE010T010.016') // 예상 출항일이 오늘 날짜보다 작을 수 없습니다.
    //       isOk = false
    //       elem.focus()
    //     } else if (today.setDate(today.getDate() + 60) < etd) {
    //        msg = this.$t('msg.FARE010T010.017') // 예상 출항일이 + 60일을 초과할 수 없습니다.
    //       isOk = false
    //     }
    //   }
    //   if (!isOk) {
    //     this.$ekmtcCommon.showErrorTooltip(elem, msg)
    //   } else {
    //     this.$ekmtcCommon.hideErrorTooltip(elem)
    //   }

    //   return isOk
    // },
    fnValidationNego (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      let isAllOk = true

      // 화물구분 validation check
      if (!this.fnValidationCargo(frm)) {
        isAllOk = false
      }
      // 선적횟수 validation check
      if (!this.fnValidationShip(frm)) {
        isAllOk = false
      }
      // commodity validation check
      if (!this.fnValidationCommodity(frm)) {
        isAllOk = false
      }
      // 전체 물량 수준 validation check
      if (!this.fnValidationAllQtyLvl(frm)) {
        isAllOk = false
      }
      // 타겟 물량 수준 validation check
      if (!this.fnValidationTgtQtyLvl(frm)) {
        isAllOk = false
      }
      // 선적기간 validation check
      if (!this.fnValidationLodingPeriod(frm)) {
        isAllOk = false
      }
      // 선적주기 validation check
      if (!this.fnValidationVolume(frm)) {
        isAllOk = false
      }
      // 선적주기 validation check
      if (!this.fnValidationTimes(frm)) {
        isAllOk = false
      }
      // 업무 담당자 validation check
      if (!this.fnValidationSelPic(frm)) {
        isAllOk = false
      }
      // 영업 담당자 validation check
      // if (!this.fnValidationSelSal(frm)) {
      //   isAllOk = false
      // }
      // Container List validation check
      if (!this.checkValidationCntnListAll(frm)) {
        isAllOk = false
      }
      return isAllOk
    },
    // 화물구분 validation check
    fnValidationCargo (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      let isOk = true
      const elemsCargoAll = frm.querySelectorAll('input[name="cargo"]')
      const elemsCargoChk = frm.querySelectorAll('input[name="cargo"]:checked')
      if (elemsCargoChk.length === 0) {
        this.$ekmtcCommon.showErrorTooltip(elemsCargoAll[0], this.$t('msg.VOSD100_M5.051') + this.$t('msg.VOSD100_M5.049')) // 화물구분을 선택해 주세요
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemsCargoAll[0])
      }
      return isOk
    },
    // 선적횟수 validation check
    fnValidationShip (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      let isOk = true
      const elemsShipAll = frm.querySelectorAll('input[name="shipment"]')
      const elemsShipChk = frm.querySelectorAll('input[name="shipment"]:checked')
      if (elemsShipChk.length === 0) {
        this.$ekmtcCommon.showErrorTooltip(elemsShipAll[0], this.$t('msg.VOSD100_M5.052') + this.$t('msg.VOSD100_M5.050')) // 선적횟수를 선택해 주세요
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemsShipAll[0])
      }
      return isOk
    },
    // Commodity validation check
    fnValidationCommodity (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelector('#commodity')
      const val = frm.querySelector('#commodity #autocomplete-form-input').value
      let isOk = true

      if (!val || (this.$ekmtcCommon.isEmpty(this.params.cmdtCd) && this.$ekmtcCommon.isEmpty(this.params.prcgCmdtDsc))) {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMBA660.011') + this.$t('msg.VOSD100_M5.058')) // Commodity 를 입력해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 전체 물량 수준 validation check
    fnValidationAllQtyLvl (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#allQtyLvl')
      let isOk = true
      if (this.params.allTeu === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.VOSD100_M5.053') + this.$t('msg.VOSD100_M5.057')) // 전체 물량 수준을 입력해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 타겟 물량 수준 validation check
    fnValidationTgtQtyLvl (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#tgtQtyLvl')
      let isOk = true
      if (this.params.targTeu === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.VOSD100_M5.054') + this.$t('msg.VOSD100_M5.057')) // 전체 물량 수준을 입력해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 선적기간 validation check
    fnValidationLodingPeriod (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#lodingPeriod')
      let isOk = true
      if (this.params.ldgTrmStrDt === '' || this.params.ldgTrmEndDt === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.VOSD100_M5.055') + this.$t('msg.VOSD100_M5.057')) // 선적기간을 입력해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
      return isOk
    },
    // 선적주기 validation check
    fnValidationVolume (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elemVol = frm.querySelectorAll('#volume')
      let isOk = true
      if (this.params.volume === '') {
        this.$ekmtcCommon.showErrorTooltip(elemVol, this.$t('msg.VOSD100_M5.056') + this.$t('msg.VOSD100_M5.050')) // 선적주기를 선택해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemVol)
        for (const vo of this.volumeList) {
          if (vo.cmdtCd === this.params.volume) {
            this.params.volumeNm = vo.cmdtDsc
            this.params.proTeuCatCd = vo.cmdtCd
          }
        }
      }
      return isOk
    },
    // 선적주기 validation check
    fnValidationTimes (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elemTimes = frm.querySelectorAll('#times')
      let isOk = true
      if (this.params.ldgCyc === '') {
        this.$ekmtcCommon.showErrorTooltip(elemTimes, this.$t('msg.VOSD100_M5.056') + this.$t('msg.VOSD100_M5.058')) // 선적주기를 입력해 주세요.
        isOk = false
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elemTimes)
      }
      return isOk
    },
    removeMsgNo (name) {
      const frm = document.querySelector('#frmReq')
      const elem = frm.querySelectorAll('#' + name)
      this.$ekmtcCommon.hideErrorTooltip(elem)
    },
    // 업무 담당자 validation check
    fnValidationSelPic (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elem = frm.querySelectorAll('#selPic')
      let isOk = true
      if (this.params.selPic === '') {
        this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.VOSD100_M5.059') + this.$t('msg.VOSD100_M5.050')) // 업무 담당자를 선택해 주세요.
        isOk = false
        this.params.bizPicNm = ''
        this.params.bizPicTelNo = ''
        this.params.bizPicEmlAddr = ''
      } else {
        this.$ekmtcCommon.hideErrorTooltip(elem)
        for (const vo of this.picNmList) {
          if (vo.picNo === this.params.selPic) {
            this.params.bizPicNm = vo.picNm
            this.params.bizPicTelNo = (vo.telPlcNo || '') + (vo.telOfcNo || '')
            this.params.bizPicEmlAddr = (vo.emlAddr || '')
          }
        }
      }
      return isOk
    },
    // 영업 담당자 validation check
    // fnValidationSelSal (frm) {
    //   if (frm === undefined) {
    //     frm = document.querySelector('#frmReq')
    //   }
    //   const elem = frm.querySelectorAll('#selSal')
    //   let isOk = true
    //   if (this.params.selSal === '') {
    //     this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.VOSD100_M5.060') + this.$t('msg.VOSD100_M5.050')) // 영업 담당자를 선택해 주세요.
    //     isOk = false
    //     this.params.bussPicNm = ''
    //     this.params.bussPicTelNo = ''
    //     this.params.bussPicEmlAddr = ''
    //   } else {
    //     this.$ekmtcCommon.hideErrorTooltip(elem)
    //     // 영업담당자 전화번호, Email
    //     this.params.bussPicNm = this.salNm
    //     this.params.bussPicTelNo = this.bussPicTelNo
    //     this.params.bussPicEmlAddr = this.bussPicEmlAddr
    //   }
    //   return isOk
    // },
    checkValidationCntnList (name, idx, frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elems = frm.querySelectorAll('*[name="' + name + '"]')
      let len = elems.length
      let isOk = true
      if (len <= idx) {
        return false
      }
      // 해당 필드에 따라서 Validation Check
      if (name === 'size') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], this.$t('msg.CSBK100.272') + this.$t('msg.VOSD100_M5.050')) // Size를 선택해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'type') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], this.$t('msg.CSBK100.273') + this.$t('msg.VOSD100_M5.050')) // Type를 선택해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'currency') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], this.$t('msg.VOSD100_M5.061') + this.$t('msg.VOSD100_M5.050')) // Currency를 선택해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'others') {
        if (elems[idx].value === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], this.$t('msg.VOSD100_M5.037') + this.$t('msg.VOSD100_M5.050')) // 타사를 선택해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      }
      return isOk
    },
    checkValidationCntnListNumber (name, idx, frm, vo) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const elems = frm.querySelectorAll('*[name="' + name + '"]')
      let len = elems.length
      let isOk = true
      if (len <= idx) {
        return false
      }
      // 해당 필드에 따라서 Validation Check
      if (name === 'reqRate') {
        if (vo.reqRate === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Ideal Rate(USD)' + this.$t('msg.VOSD100_M5.057')) // Ideal Rate를 입력해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'proQty') {
        if (vo.proQty === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Volume(TEU/' + this.$t('msg.VOSD100_M5.035') + ')' + this.$t('msg.VOSD100_M5.058')) // Volume(TEU/월)을 입력해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'weight') {
        if (vo.weight === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], 'Weight(KG/Van)' + this.$t('msg.VOSD100_M5.057')) // Weight(KG/Van)를 입력해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      } else if (name === 'othersLvl') {
        if (vo.cmptCoFrt === '') {
          this.$ekmtcCommon.showErrorTooltip(elems[idx], this.$t('msg.VOSD100_M5.036') + this.$t('msg.VOSD100_M5.058')) // 타사 운임수준을 입력해 주세요.
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(elems[idx])
        }
      }
      return isOk
    },
    checkValidationCntnListAll (frm) {
      if (frm === undefined) {
        frm = document.querySelector('#frmReq')
      }
      const len = this.params.containerList.length
      const arrCols = ['size', 'type', 'currency', 'others']
      const colLen = arrCols.length
      let isOk = true
      for (let i = 0; i < len; i++) {
        for (let j = 0; j < colLen; j++) {
          if (!this.checkValidationCntnList(arrCols[j], i, frm)) {
            isOk = false
          }
        }
      }
      const arrColsNo = ['reqRate', 'proQty', 'weight', 'othersLvl']
      const colLenNo = arrColsNo.length
      let m = 0
      for (const vo of this.params.containerList) {
        for (let j = 0; j < colLenNo; j++) {
          if (!this.checkValidationCntnListNumber(arrColsNo[j], m, frm, vo)) {
            isOk = false
          }
        }
        m++
      }
      return isOk
    },
    removeMsgNumber (name, idx) {
      const frm = document.querySelector('#frmReq')
      const elems = frm.querySelectorAll('*[name="' + name + '"]')
      this.$ekmtcCommon.hideErrorTooltip(elems[idx])
    }
  }
}
</script>
